import {WidgetData} from '@wix/events-types'
import {
  EventsHttpClient,
  EVENT_FILTER_TYPE,
  EVENT_SORT_ORDER,
  LIST_LAYOUT,
  RECURRING_FILTER,
  WIDGET_TYPE,
} from '@wix/wix-events-commons-statics'
import {addQueryParams, stringifyParams} from '@wix/panda-js-utils'

export interface GetWidgetDataParams {
  compId: string
  language: string
  locale: string
  tz: string
  viewMode: string
  members: boolean
  paidPlans: boolean
  responsive: boolean
  showcase: boolean
  widgetType?: WIDGET_TYPE
  listLayout?: LIST_LAYOUT
  recurringFilter?: RECURRING_FILTER
  filterType?: EVENT_FILTER_TYPE
  sortOrder?: EVENT_SORT_ORDER
  categoryId?: string
  newDemo?: boolean
  noCategoryIdFallback?: boolean
}

export const getWidgetData =
  (api: EventsHttpClient) =>
  async ({
    responsive = false,
    locale,
    language,
    compId,
    viewMode,
    members,
    paidPlans,
    widgetType,
    listLayout,
    recurringFilter,
    showcase = false,
    tz,
    filterType,
    sortOrder,
    newDemo,
    categoryId,
    noCategoryIdFallback,
  }: GetWidgetDataParams): Promise<WidgetData> =>
    api.get<WidgetData>(
      addQueryParams(
        `/html/v2/widget-data`,
        stringifyParams({
          compId,
          locale: language,
          regional: locale,
          viewMode,
          members,
          paidPlans,
          responsive,
          widgetType,
          listLayout,
          recurringFilter,
          showcase,
          tz,
          filterType,
          sortOrder,
          newDemo,
          categoryId,
          noCategoryIdFallback,
        }),
      ),
    )
