import {AnyAction} from 'redux'
import {initSettings, updateStyleParams} from '../actions/component'
import {State, WidgetComponentConfig} from '../types/state'

const defaultState: WidgetComponentConfig = {} as WidgetComponentConfig

export const component = (state = defaultState, action: AnyAction): WidgetComponentConfig => {
  switch (action.type) {
    case initSettings.fulfilled.toString():
      return {
        ...state,
        settings: action.payload,
      }
    case updateStyleParams.toString():
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload.numbers,
          ...action.payload.booleans,
        },
        params: {
          ...action.payload.numbers,
          ...action.payload.booleans,
        },
      }
    default:
      return state
  }
}

export const getComponentEventIds = (state: State) => state.component.byEventId?.eventId
